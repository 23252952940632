<template>
  <div class="messageListView">
    <van-sticky>
      <van-nav-bar title="我的工单" left-arrow @click-left="onClickLeft" />
    </van-sticky>
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" loading-text="..." @load="onLoad">
      <div v-for="(item, key) in list" :key="key" class="xiax_item">
        <van-panel :title="'标题:' + item.title" :status="item.addtime" :desc="'内容:' + item.content">
          <div class="replay">
            <div class="replayTitle">工单回复:</div>
            <div class="replayContent" v-if="!item.reply">暂无回复</div>
            <div class="replayContent" v-if="item.reply">{{ item.reply }}</div>
          </div>
        </van-panel>
      </div>
    </van-list>
  </div>
</template>

<script>
import { message_lists } from '@/api/message';
export default {
  data() {
    return {
      list: [],
      page: 1,
      pageSize: 15,
      loading: false,
      finished: false,
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    //获取留言列表
    getMessageLists() {
      message_lists({ page: this.page, pageSize: this.pageSize })
        .then((response) => {
          this.list.push.apply(this.list, response.result);
          // 加载状态结束
          this.loading = false;
          this.page++;
          if (response.result.length == 0) {
            this.finished = true;
          }
        })
        .catch((error) => {});
    },
    onLoad() {
      this.getMessageLists();
    },
  },
};
</script>
<style lang="stylus">
.messageListView
  .van-list
    margin-top: 0.3rem
    .xiax_item
      padding: 0.15rem 0.4rem
      .replay
        padding: 0.26667rem 0.42667rem
        .replayTitle
          display: inline-block
          font-weight: bold
        .replayContent
          display: inline-block
</style>
